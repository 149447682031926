import { NavLink } from '@remix-run/react'
import React from 'react'
import { CommerceBearIcon } from '~/components/ui/commerce-bear-icon'
import { cn } from '~/lib/utils'

interface AuthLayoutProps {
  children: React.ReactNode
  title: string | React.ReactNode
}

export function AuthLayout({ children, title }: AuthLayoutProps) {
  return (
    <div className={cn('brand', 'flex flex-col lg:flex-row h-screen')}>
      <aside className={cn('bg-accent', 'py-8 container', 'flex-1 lg:flex-auto flex')}>
        <div className={cn('lg:px-16 lg:pb-12', 'flex-1 flex flex-col justify-between gap-6')}>
          <NavLink to="/">
            <CommerceBearIcon className="h-16 w-16" />
          </NavLink>
          <div>
            <h1 className="text-6xl lg:text-8xl font-anton">{title}</h1>
          </div>
        </div>
      </aside>
      <main className={cn('bg-background', 'py-8 container', 'flex')}>
        <div className={cn('lg:px-8 lg:pb-12', 'flex-1 lg:flex-auto flex items-end lg:items-end w-full')}>
          {children}
        </div>
      </main>
    </div>
  )
}
